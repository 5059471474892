<template>
  <div>
    <Header :wwwUrl="wwwUrl" />
    <div class="app-content">
      <div class="screen" v-for="(item, index) in list" :key="index">
        <el-image :src="item.cover_img" lazy @load="getCase(index)"></el-image>
        <div class="screen-item">
          <a class="screen-item-title">{{ item.active_title }}</a>
          <vue-qr
            class="screen-item-qrcode"
            :text="item.url"
            :margin="0"
            :size="150"
            :auto-color="true"
            :dot-scale="1"
          ></vue-qr>
          <a class="screen-item-btn">扫码查看活动</a>
        </div>
      </div> 
    </div>
    <el-backtop :visibility-height="1000"></el-backtop>
  </div>
</template>

<script>
import { getActiveAll } from '@/api/index'
import vueQr from 'vue-qr'
import Header from '@/components/Header'
export default {
  components: {
    vueQr,
    Header
  },
  data() {
    return {
      list: [], // 案例场景数据
      size: 36, // 每页多少条
      wwwUrl: 'https://www.huodongge.cn'
    }
  },
  methods: {
    // 获取案例场景图片
    async getCase(index) {
      if (index == this.list.length - 1 || typeof index == 'undefined') {
        let page = this.list.length / this.size + 1
        let params = {
          page: page,
          size: this.size
        }
        const res = await getActiveAll(params)
        if (res.status == 0) {
          this.list = this.list.concat(res.data.items)
        }
      }
    },
  },
  created() {
    this.$store.dispatch('app/getDomainList', 'www').then(res => {
      if (res) {
        this.wwwUrl = res.url
      }
    })
    this.getCase()
  }
}
</script>

<style lang="scss" scoped>
.app-content {
  padding-top: 120px;
  width: 1200px;
  margin: 0 auto;
  padding-left: 55px;
  .screen {
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-shadow: 0 0 0 1px rgba(58, 58, 58, 0.05);
    width: 250px;
    height: 364px;
    margin-right: 30px;
    margin-bottom: 20px;
    .el-image {
      width: 100%;
      height: 100%;
    }
    .screen-item {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      backface-visibility: hidden;
      background-color: hsla(0, 0%, 100%, 0.98);
      box-shadow: 0 20px 40px 0 rgba(58, 58, 58, 0.2);
      color: #3a3a3a;
      opacity: 0;
      transform: translateZ(0);
      transition: opacity 0.25s ease-out 0s;
    }
    .screen-item-title {
      position: absolute;
      top: 200px;
      left: 0;
      right: 0;
      padding: 30px;
      text-align: center;
      text-shadow: 3px 3px 3px #bbb;
    }
    .screen-item-qrcode {
      box-shadow: 0 20px 40px 0 rgba(58, 58, 58, 0.3);
      padding: 3px;
      border-radius: 2px;
      display: block;
      margin: 0 auto;
    }
    .screen-item-btn {
      background-color: #fff;
      border: 1px solid #10c55b;
      border-radius: 3px;
      color: #10c55b;
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      padding: 4px 10px;
      text-align: center;
      width: 180px;
      margin: 0 auto;
      margin-top: 30px;
    }
    .screen-item-btn:hover {
      background-color: #10c55b;
      color: #fff;
    }
  }
  .screen:hover {
    .screen-item {
      opacity: 1;
    }
  }
}
</style>
